import React, {useEffect, useState} from 'react';
import Select from 'react-select';

// components
import { useTranslation } from "react-i18next";

// style
import classes from './SelectSortBestPlayersBy.module.css';
import {getAppStatus} from "../../utils/services";
import {toast} from "react-toastify";


const SelectSortBestPlayersBy = ({ changeFilters, disabled }) => {

  const { t } = useTranslation();
  useEffect(() => {
    if (disabled) {
      handleChange({
        value: 'public',
        text: t('By Public'),
      });
    }
  }, [disabled]);

  const sortBestPlayersBy = [
    {
      value: 'public',
      text: t('By Public'),
    },
    {
      value: 'coach',
      text: t('By Coach'),
    },
    {
      value: 'internal',
      text: t('By Players'),
    },
    {
      value: 'journalist',
      text: t('By Journalist'),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(sortBestPlayersBy[0]);

  // handle onChange event of the dropdown
  const handleChange = e => {
    setSelectedOption(e);
    changeFilters(e.value)
  }

  // SELECT STYLES
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: 'transparent',
      color: '#141414',
      padding: '9px 15px 9px 0',
      fontSize: 16,
      lineHeight: '21px',
      fontFamily: 'Avenir-Roman',
      borderBottom: '1px solid #3C3C3C',
      cursor: 'pointer',
      "&:last-of-type": {
        borderBottom: 'none',
      },
      "&:hover:after": {
        background: '#73b76b40'
      },
      "&:active": {
        background: 'transparent',
      },
      "&:after": {
        content: '""',
        width: 7,
        height: 7,
        background: state.isSelected ? '#73B76B' : 'transaprent',
        borderRadius: '50%',
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%)'
      }
    }),
    control: (base, state) => ({
      transition: 'border .3s ease',
      display: 'flex',
      width: '100%',
      cursor: 'pointer',
      background: state.selectProps.menuIsOpen ? "transparent" : '#fff3',
      borderRadius: 20,
      zIndex: state.selectProps.menuIsOpen ? '98' : '',
    }),
    indicatorSeparator: () => { },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#fff'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '8px 0 8px 15px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: '#fff',
        width: '100%',
        fontSize: 16,
        lineHeight: '21px',
        fontFamily: 'Avenir-Book'
      };
    },
    container: (provided, state) => ({
      width: '100%',
      "&:after": {
        content: '""',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: state.selectProps.menuIsOpen ? '#00000080' : 'transaprent',
        zIndex: state.selectProps.menuIsOpen ? '99' : '-1',
        display: state.selectProps.menuIsOpen ? 'block' : 'none',
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 190,
      marginTop: 10,
      borderRadius: '22px',
      boxShadow: 'none',
      paddingLeft: '15px',
      background: '#fff',
      right: 0,
      zIndex: state.selectProps.menuIsOpen ? '100' : '',
      "&:after": {
        content: '""',
        position: 'absolute',
        right: '40px',
        top: '-13px',
        width: 0,
        height: 0,
        borderLeft: '13px solid transparent',
        borderRight: '13px solid transparent',
        borderBottom: '13px solid #fff',
        clear: 'both',
      }
    }),
    menuList: (base) => ({
      ...base,
      height: 'auto',
      maxHeight: 'auto',
      minHeight: "auto",
      padding: 0
    }),
  }

  return (
    <>
      <div className={classes.sxl_select_club_holder}>
        <Select
          styles={customStyles}
          placeholder={t("Sort by")}
          value={selectedOption}
          defaultValue={sortBestPlayersBy[0]}
          options={sortBestPlayersBy}
          onChange={handleChange}
          noOptionsMessage={() => t("No parameters")}
          isSearchable={false}
          blurInputOnSelect
          closeMenuOnSelect
          isDisabled={disabled}
          // menuIsOpen
          getOptionLabel={e => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{e.text}</span>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default SelectSortBestPlayersBy;
