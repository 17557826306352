import React from 'react';
import "./CountDown.css"
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

const CountDownComponent = ({ untilDate }) => {

    const { t } = useTranslation();

    // Random component
    const Completionist = () => <span>{t('closed')}</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed, days }) => {

        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

        function mapNumber(number, in_min, in_max, out_min, out_max) {
            return (
                ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
            );
        }

        const SVGCircle = ({ radius }) => (
            <svg className="countdown-svg">
                <path
                    fill="none"
                    stroke="#d4ab40"
                    strokeWidth="4"
                    d={describeArc(50, 50, 40, 0, radius)}
                />
            </svg>
        );

        function describeArc(x, y, radius, startAngle, endAngle) {
            var start = polarToCartesian(x, y, radius, endAngle);
            var end = polarToCartesian(x, y, radius, startAngle);

            var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

            var d = [
                'M',
                start.x,
                start.y,
                'A',
                radius,
                radius,
                0,
                largeArcFlag,
                0,
                end.x,
                end.y
            ].join(' ');

            return d;
        }

        function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

            return {
                x: centerX + radius * Math.cos(angleInRadians),
                y: centerY + radius * Math.sin(angleInRadians)
            };
        }

        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown

            return (<div className="countdown-wrapper">
                <div className="countdown-item">
                    <SVGCircle radius={daysRadius} />
                    {days}
                    <span className='item-title'>{t('days')}</span>
                </div>
                <div className="countdown-item">
                    <SVGCircle radius={hoursRadius} />
                    {hours}
                    <span className='item-title'>{t('hours')}</span>
                </div>
                <div className="countdown-item">
                    <SVGCircle radius={minutesRadius} />
                    {minutes}
                    <span className='item-title'>{t('minutes')}</span>
                </div>
                <div className="countdown-item">
                    <SVGCircle radius={secondsRadius} />
                    {seconds}
                    <span className='item-title'>{t('seconds')}</span>
                </div>

            </div>
            )
        }
    };

    return (<Countdown renderer={renderer} date={untilDate} />)
}

export default CountDownComponent