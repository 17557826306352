import React from 'react'

// styles
import classes from "./PlayerInfo.module.css";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from "react-i18next";

const PlayerInfo = ({
  show,
  step,
  onCloseInfos,
  sortedParams,
  playersMethods: {
    added,
    setRemoving,
    setAdded,
    playersData
  },
  addToTop,
  removeFromTop }) => {

  const { t } = useTranslation();
  const [playerStat, setPlayerStat] = React.useState()

  const handleCloseClick = (e) => {
    onCloseInfos();
  };

  React.useEffect(() => {
    let array = []

    if (playersData && show === true) {
      const params = sortedParams(playersData?.position)
      params.forEach(element => {
        playersData?.stat && Object.entries(playersData?.stat).map(([key, value]) => {
          if (element.param_name === key) {
            console.log(element.param_name === key, element.param_name, key)
            array.push({ key, value, name: element?.param_text, tooltip: element?.tooltip })
          }
        })
      });
      setPlayerStat([...array])
    }

  }, [show])

  const handleSubmitCloseClick = (e) => {
    if (added) {
      setRemoving(true);
      removeFromTop()
    } else {
      setAdded(!addToTop())
    }
    onCloseInfos();
  };

  React.useEffect(() => {
    onCloseInfos();
  }, [step.position])

  const getPosition = (position) => {

    if (position === 'G') return 'Goalkeeper'
    if (position === 'D') return 'Defender'
    if (position === 'M') return 'Midfielder'
    if (position === 'F') return 'Striker'

  }

  const positionSpins_index = () => {
    if (playersData?.stat?.spins_index.length < 6) return 125
    else if (playersData?.stat?.spins_index.length < 7) return 135
    else return 140
  }

  return (
    <div className={`${classes.sxl_infos_right} ${show ? classes.sxl_show_right : ""}`}>
      <div className={classes.sxl_infos_right_title}>
        <div className={classes.sxl_back_holder} onClick={handleCloseClick}>
          <img src={require("../img/arrow_left.svg")} alt="" /> {t('Back')}
        </div>
        {t('Player Information')}
        <div className={classes.sxl_back_stats_icon}></div>
      </div>

      <div className={classes.sxl_main_info}>
        <div className={classes.sxl_img_holder}>
          <img src={`${process.env.REACT_APP_API}` + playersData?.image?.formats?.thumbnail?.url} alt={playersData?.lastname} />
        </div>
        <div className={classes.sxl_info}>
          <div className={classes.sxl_name}>{playersData?.firstname + ' ' + playersData?.lastname}</div>
          <div className={classes.sxl_info_position}>{t(getPosition(playersData?.position))}</div>
          <div className={classes.sxl_index}>
            <strong>{t('SPINS Index')}</strong>: {playersData?.stat?.spins_index}<div style={{ position: 'absolute', left: positionSpins_index(), bottom: 5 }}><span style={{ border: 'solid', borderWidth: 1, borderRadius: 25, paddingLeft: 4, paddingRight: 4, color: '#A38E60', fontSize: 10, marginLeft: 3 }} data-for="params" data-tip={t('spins_index_tooltip')}>?</span></div></div>
          <div className={classes.sxl_team}>
            <img src={playersData?.club?.logo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_API}` + playersData?.club.logo.formats.thumbnail.url : `${process.env.REACT_APP_API}` + playersData?.club.logo.url} alt="" />{" "}
            {playersData?.club?.name}
          </div>
          <img
            className={classes.sxl_small_screen_xl_logo}
            src={require("../img/xl_bg_logo.png")}
            alt=""
          />
        </div>
      </div>

      {/* <div className={classes.sxl_stats_title}>{t('Player Statistics')}</div>

      <div className={classes.sxl_stats_table}>
        <>
          {playerStat && playerStat[0] && playerStat.map((player) => <div key={player.key} className={classes.sxl_stats_table_item} >
            <div data-tip={player.tooltip} data-for="params" className={classes.sxl_stats_table_key} style={{ display: "flex", height: 16 }}>{t(player.name)} {player.tooltip && <div style={{ border: 'solid', borderWidth: 1, borderRadius: 25, paddingLeft: 4, paddingRight: 4, color: '#A38E60', fontSize: 10, marginLeft: 3 }}>?</div>}</div>
            <div className={classes.sxl_stats_table_value}>{player.value}</div>
            <ReactTooltip multiline={true} id="params" />
          </div>)}


        </>
      </div> */}

      <div className={classes.sxl_info_ctas_holder}>
        <div className={classes.sxl_info_back} onClick={handleCloseClick}>
          <img src={require("../img/arrow_left.svg")} alt="Arrow left" /> {t('Back')}
        </div>
        <div className={classes.sxl_info_ctas}>
          {added ? <div className={classes.sxl_info_cta_remove} onClick={handleSubmitCloseClick}>{t('Remove')}</div> :
            <div className={classes.sxl_info_cta} onClick={handleSubmitCloseClick}>{t('Select')}</div>
          }
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;