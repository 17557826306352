import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  FacebookIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,

} from "react-share";
import { useLocation } from 'react-router-dom';
import { sendEmail } from '../utils/services';

// components
import ResultsRules from '../components/ResultsRules';

// style
import classes from './SuccessfullySubmitted.module.css';

const Home = () => {

  const { t } = useTranslation();
  const [email, setEmail] = useState('')
  const [displayEmail, setDisplayEmail] = useState(true)
  const location = useLocation();
  const voterID = location?.state?.voteID
  const vote_hash = location?.state?.vote_hash

  const handleClick = () => {

  }

  return (
    <>
      <div className="sxl_wrapper">
        <div className="sxl_container">
          <div className={classes.sxl_home_content}>
            <div className={classes.sxl_main_logo}>
              <Link to="/">
                <img style={{ width: 360 }} src={require("../img/logo.png")} alt="SpinsXL logo" />
              </Link>
            </div>
            <div className={classes.sxl_title_msg}>
              <h1>{t('You Have Successfully Submitted')} <span>{t('Your Vote')}</span></h1>
            </div>
            { /*displayEmail && !vote_hash && <div className={classes.sxl_title_msg} style={{ maxWidth: '22rem' }}>
              <h1 style={{ fontSize: 16, lineHeight: 1.5 }}>{t('Give away text')}</h1>
              <div className="sxl_input_holder" >
                <input
                  value={email}
                  type="text"
                  style={{ paddingLeft: 10 }}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className={classes.sxl_vote_btn} style={{ marginTop: 20 }}>
               <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (email === '' || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
                      alert(t('Please enter valid email'))
                    } else {
                      sendEmail(email, voterID)
                        .then(res => {
                          alert(t('Successfully sent'));
                          setDisplayEmail(false);
                        })
                        .catch(error => alert(error.message))
                    }
                  }} >
                  {t('Send')}
                </div>
              </div>
                </div> */}
            <div className={classes.sxl_vote_btn}>
              <div>
                <img src={require("../img/share.png")} alt="share" /> {t('Share With Friends')}
              </div>
            </div>
            <div style={{ paddingBottom: 50 }}>

              <FacebookShareButton
                quote='Izbral(a) sem najboljše nogometaše in trenerja 1. SNL v jesenskem delu sezone 2023/2024. Pojdi na https://spins11.si in oddaj glas tudi ti!'
                url={"https://spins11.si/"}>
                <FacebookIcon />
              </FacebookShareButton>

              <ViberShareButton
                style={{ paddingLeft: 10 }}
                title='Izbral(a) sem najboljše nogometaše in trenerja 1. SNL v jesenskem delu sezone 2023/2024. Pojdi na https://spins11.si in oddaj glas tudi ti!'
                url={"https://spins11.si/"}>
                <ViberIcon />
              </ViberShareButton>

              <WhatsappShareButton
                style={{ paddingLeft: 10 }}
                title='Izbral(a) sem najboljše nogometaše in trenerja 1. SNL v jesenskem delu sezone 2023/2024. Pojdi na https://spins11.si in oddaj glas tudi ti!'
                url={"https://spins11.si/"}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
            <div className={classes.sxl_logos_holder}>
              {/* <div className={classes.sxl_logos}>
                <img src={require("../img/home-logos/telekomslovenije.svg")} alt="Telekom Slovenije logo" />
                <img src={require("../img/home-logos/NZS_osnovni_lezeci.svg")} alt="NZS logo" />
              </div> */}
              <div className={classes.sxl_logos}>
                <a href='https://www.avto-aktiv.si/' target="_blank" rel="noreferrer">
                  <img className={classes.sxl_logo_item} src={require("../img/home-logos/AvtoAktiv.png")} alt="Avto Aktiv logo" />
                </a>
                <a href='https://www.prowellness.si/' target="_blank" rel="noreferrer">
                  <img className={classes.sxl_logo_item} src={require("../img/home-logos/ProWelles.png")} alt="Technogym logo" />
                </a>
                <img className={classes.sxl_logo_item} src={require("../img/home-logos/TehnoGym.png")} alt="Pro Wellness logo" />
                <a href='https://www.grandplazahotel.si/' target='_blank'>
                  <img className={classes.sxl_logo_item} src={require("../img/home-logos/Logo_Plaza1.jpg")} alt="Grand Plaza Hotel" />
                </a>
              </div>
            </div>
            <ResultsRules classes={classes} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;
