import React from 'react'
import { Link } from 'react-router-dom';

// components
import ResultsRules from './ResultsRules';

// styles
import classes from './Footer.module.css';

const Footer = () => {
  return (
    <section className={classes.sxl_footer}>
      <div className="sxl_container">
        <div className={classes.sxl_footer_content}>
          <ResultsRules classes={classes} />
          <div className={classes.sxl_logos}>
            <a href='https://www.avto-aktiv.si/' target="_blank" rel="noreferrer">
              <img className={classes.sxl_logo_item} src={require("../img/home-logos/AvtoAktiv.png")} alt="Avto Aktiv logo" />
            </a>
            <a href='https://www.prowellness.si/' target="_blank" rel="noreferrer">
              <img className={classes.sxl_logo_item} src={require("../img/home-logos/ProWelles.png")} alt="Technogym logo" />
            </a>
            <img className={classes.sxl_logo_item} src={require("../img/home-logos/TehnoGym.png")} alt="Pro Wellness logo" />
            <a href='https://www.grandplazahotel.si/' target='_blank'>
              <img className={classes.sxl_logo_item} src={require("../img/home-logos/Logo_Plaza1_White.png")} alt="Grand Plaza Hotel" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer;