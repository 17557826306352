import React, { useState, useEffect } from 'react'
import Carousel from 'react-spring-3d-carousel';
import { useWindowSize } from '../../utils/windowSize'
import { Link } from "react-router-dom";

const CarouselSlider = ({ fetchedImages, setIsLoading }) => {

    const [slideTo, setSlideTo] = useState(0)
    const [offsetRadius, setOffsetRadius] = useState(1)
    const [height, width] = useWindowSize();
    const [images, setImages] = useState()

    useEffect(() => {
        if (width < 800) setOffsetRadius(1)
        else if (width < 1024) setOffsetRadius(1)
        if (width > 1024) setOffsetRadius(2)
    }, [width])

    useEffect(() => {
        let timer = setInterval(() => {
            setSlideTo(currentNum => {
                if (images?.length - 1 === currentNum) {
                    return 0
                }
                return currentNum + 1
            })
        }, 4000)
        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideTo])

    useEffect(() => {
        if (fetchedImages && fetchedImages[0]) {
            const array = fetchedImages.map((item, index) => ({
                key: index,
                onClick: () => setSlideTo(index),
                content: <div style={width < 800 ? slideTo === 0 ? {} : {} : {}}>
                    <Link style={{ color: '#d4ab40', textDecoration: 'none' }}
                        to={{
                            pathname: "/vote",
                            state: { params: item.parameter.param_name, position: item.position, param_text: item.parameter.param_text }
                        }}>
                        <img src={`${process.env.REACT_APP_API}` + item?.image?.url} alt={item?.DisplayText} />
                    </Link>
                    <span className={'carusellName'}>
                        <Link
                            style={{ color: '#d4ab40', textDecoration: 'none' }}
                            to={{
                                pathname: "/vote",
                                state: { params: item.parameter.param_name, position: item.position, param_text: item.parameter.param_text }
                            }}>{item?.DisplayText}
                        </Link>
                    </span>
                </div>
            }))
            setImages(array)
            setIsLoading(false)
        }
    }, [fetchedImages])

    return (<>
        {images && <div className="carusel-holder">
            {width < 800 && <div className="prev" style={{ opacity: 0, backgroundColor: 'black' }} onClick={() => setSlideTo(currentNum => {
                if (currentNum === 0) return images.length - 1
                else return currentNum - 1
            })
            }>Prev</div>
            }
            <Carousel
                slides={images}
                goToSlide={slideTo}
                showNavigation={false}
                offsetRadius={offsetRadius}
                animationConfig={{
                    mass: 1,
                    tension: 170,
                    friction: 26,
                    precision: 0.9
                }} />
            {width < 800 && <div className="next" style={{ opacity: 0 }} onClick={() => setSlideTo(currentNum => {
                if (images.length - 1 === currentNum) return 0
                else return currentNum + 1
            })}> Next</div>
            }
        </div>}
    </>
    )
}


export default CarouselSlider
