import React, { useState, useEffect } from 'react'

// pages
import BestPlayersRightNow from './BestPlayersRightNow';
import BestPlayer from './BestPlayer';
import BestCoach from './BestCoach';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
    getAppStatus,
    getResults,
    getPeriods
} from '../utils/services'
import { PuffLoader } from 'react-spinners';

// components
import Navigation from '../components/Navigation';

// utils

import { toast } from 'react-toastify';

const ResultsPage = () => {

    const [selected, setSelected] = React.useState({
        bestcoach: false,
        bestplayer: false,
        bestplayers11: true
    })

    const [top, setTop] = useState({
        G: [],
        D: [],
        M: [],
        F: [],
        C: [],
        T: [],
        vote_count: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [appStatus, setAppStatus] = useState('Open');
    const [periods, setPeriods] = React.useState()
    // const [league, setLeague] = useState<league>('M1');
    const [filters, setFilters] = useState({
        period: null,
        audience: "public"
    })

    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const redirect = location.search.split('=')[1];
        redirect === 'true' &&
            toast.warning(t('Voting is currently closed, final results will be published soon'));

        // GET ALL PERIODS AND SORT THEM
        getPeriods().then(res => {
            const sortedItems = res.sort((a, b) => !a.active || a.id < b.id) //first active then by id
            const items = sortedItems.map(item => ({ value: item.id, text: item.name, active: item.active }))
            if (items && items[0]) {
                setPeriods(items)
                changeFilters("public", items[0].value)
            }
        })
    }, []);

    useEffect(() => {
        if (periods) {
            getAppStatus()
                .then((res) => {
                    setAppStatus(res);
                    _getResults(periods[0].value);
                })
                .catch((error) =>
                    toast.warning(error.message),
                )
        }
    }, [periods]);

    useEffect(() => {
        if (filters.period && filters.audience) {
            setIsLoading(true);
            getResults(filters.audience, filters.period).then(res => {
                setTop(res);
                setIsLoading(false);
            }).catch(error => {
                toast.warning(error.message);
                setIsLoading(false);
            })
        }
    }, [filters]);

    const _getResults = async (period) => {
        setIsLoading(true);
        try {
            const res = await getResults(filters.audience, period);
            setTop(res);
            setIsLoading(false);
        } catch (error) {
            toast.warning(error.message);
            setIsLoading(false);
        }
    };

    const changeFilters = (audience, period) => {

        if (period) {
            setFilters((prevState) => ({
                ...prevState,
                period
            }));
        }

        if (audience) {
            setFilters((prevState) => ({
                ...prevState,
                audience
            }));
        }
    }

    return (
        <>
            {isLoading && (
                <div className="spinerClass">
                    <PuffLoader
                        color={'#A38E60'}
                        size={200}
                        loading={isLoading}
                    />
                </div>
            )}
            <Navigation selected={selected} setSelected={setSelected} />
            {selected.bestcoach && <BestCoach bestC={top?.bestC} changeFilters={changeFilters} periods={periods} disableAudience={false} />} {/*periods?.filter(x=> x.value==filters.period)[0]?.active*/}
            {selected.bestplayer && <BestPlayer bestP={top?.bestP} changeFilters={changeFilters} periods={periods} disableAudience={false} />}
            {selected.bestplayers11 && <BestPlayersRightNow top11={top?.top11} changeFilters={changeFilters} periods={periods} disableAudience={false} />}
        </>
    )
}

export default ResultsPage;
