import React, { useEffect, useState } from 'react';

const ListItemView = ({
    player,
    addToTop,
    removeFromTop,
    isAdded,
    bestPlayerSelecting,
    showPlayerInfosClick,
    bestCoachSelecting,
    setPlayersMethods,
    isBestPlayerSelected,
    sortFilter
}) => {
    const { id, firstname, lastname, position, club, stat } = player;
    const [added, setAdded] = useState(bestPlayerSelecting ? isBestPlayerSelected : isAdded);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        if (bestPlayerSelecting) removing ? setAdded(false) : setAdded(isBestPlayerSelected);
        else removing ? setAdded(false) : setAdded(isAdded);
    }, [isAdded, isBestPlayerSelected])

    return (
        <tr key={id} className={added ? "sxl_table_border_bottom sxl_row_checked" : "sxl_table_border_bottom"}>
            <td align="left">
                <div className="sxl_table_name" onClick={() => bestCoachSelecting === false && setPlayersMethods({
                    playersData: player,
                    added: added,
                    setAdded: setAdded,
                    setRemoving: setRemoving
                }) & showPlayerInfosClick()}>{lastname + ' ' + firstname.charAt(0) + '.'}</div>
            </td>
            {!bestCoachSelecting && <td align="center">
                <div className="sxl_table_value">{sortFilter && sortFilter === "stat.instat_index" ? stat['spins_index'] : stat[sortFilter.slice(5, sortFilter.lenght)]}</div>
            </td>}
            <td align="center">
                <div className="sxl_table_team">
                    <img
                        src={`${process.env.REACT_APP_API}` + club?.logo?.url}
                        alt={''}
                    />
                    {club?.name}
                </div>
            </td>
            <td align="center" >
                <div className="sxl_table_vote">
                    <label className="container">
                        <input type="checkbox" onClick={() => {
                            if (added) {
                                setRemoving(true);
                                removeFromTop(position);
                            } else {
                                setAdded(!addToTop(position))
                            }
                        }} />
                        <span className={added ? "checkmark checkedMark" : "checkmark"}></span>
                        {added && <p className="checkedArrow"></p>}
                    </label>
                </div>
            </td>
        </tr>
    );
};

export default ListItemView;
