import React from 'react'
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

// styles
import classes from "./SelectPlayersField.module.css";

// utils
import { useWindowSize } from '../utils/windowSize'

const  SelectPlayersField = (props) => {

  const { onOpenRight, onPlayerClick, otherState, setSelecting } = props;
  const { t } = useTranslation();
  const [height, width] = useWindowSize();

  const handleClickNext = () => {
    if (otherState?.top11?.G?.length === 0) return toast.warning(t("Fill in all the players"))
    else if (otherState?.top11?.D?.length < 4) return toast.warning(t("Fill in all the players"))
    else if (otherState?.top11?.M?.length < 3) return toast.warning(t("Fill in all the players"))
    else if (otherState?.top11?.F?.length < 3) return toast.warning(t("Fill in all the players"))
    else props.setStep({ position: 2 })
    setSelecting((prevState) => ({
      bestCoachSelecting: false,
      playerSelecting: false,
      bestCoachSelecting: true,
    }))
    onPlayerClick("C")
    props.setStep({ position: 2 })
  }

  const handleClick = (position, number) => {
    if (otherState?.top11[position][number]) {
      onPlayerClick(position, otherState.top11[position][number])
    } else {
      onPlayerClick(position)
      if (width < 767) onOpenRight()
    }
  }

  return (
    <div className={classes.sxl_select_players_left}>
      <img
        src={require("../img/field_1.png")}
        alt="field bg"
        className={classes.sxl_field_bg}
      />
      <img
        src={require("../img/field_small.png")}
        alt="field bg small reso"
        className={classes.sxl_field_bg_small}
      />

      <div className={classes.sxl_select_players}>

        {/* GK */}
        <div className={classes.sxl_select_gk}>
          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('G', 0)}>
              <img src={otherState?.top11?.G[0]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.G[0]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.G[0]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.G[0]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Goalkeeper')}</div>
            }
          </div>
        </div>

        {/* DEF */}
        <div className={classes.sxl_select_def}>
          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('D', 0)}>
              <img src={otherState?.top11?.D[0]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.D[0]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.D[0]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.D[0]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Defender')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('D', 1)}>
              <img src={otherState?.top11?.D[1]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.D[1]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.D[1]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.D[1]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Defender')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('D', 2)}>
              <img src={otherState?.top11?.D[2]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.D[2]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.D[2]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.D[2]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Defender')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('D', 3)}>
              <img src={otherState?.top11?.D[3]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.D[3]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.D[3]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.D[3]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Defender')}</div>
            }
          </div>
        </div>

        {/* MID */}
        <div className={classes.sxl_select_mid}>
          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('M', 0)}>
              <img src={otherState?.top11?.M[0]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.M[0]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.M[0]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.M[0]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Midfielder')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('M', 1)}>
              <img src={otherState?.top11?.M[1]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.M[1]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.M[1]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.M[1]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Midfielder')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('M', 2)}>
              <img src={otherState?.top11?.M[2]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.M[2]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.M[2]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.M[2]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Midfielder')}</div>
            }
          </div>
        </div>

        {/* ATT */}
        <div className={classes.sxl_select_att}>
          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('F', 0)}>
              <img src={otherState?.top11?.F[0]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.F[0]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.F[0]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.F[0]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Striker')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('F', 1)}>
              <img src={otherState?.top11?.F[1]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.F[1]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.F[1]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.F[1]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Striker')}</div>
            }
          </div>

          <div className={classes.sxl_player_item}>
            <div className={classes.sxl_player_img} onClick={() => handleClick('F', 2)}>
              <img src={otherState?.top11?.F[2]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.F[2]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="+" />
            </div>
            {otherState?.top11?.F[2]?.lastname ?
              <div className={classes.sxl_player_last_name}>
                {otherState?.top11?.F[2]?.lastname}
              </div> :
              <div className={classes.sxl_player_position}>{t('Striker')}</div>
            }
          </div>

        </div>

      </div>
      <div className={classes.sxl_select_ctas}>
        <div
          className={classes.sxl_select_cta_white_small}
          onClick={onOpenRight}
        >
          {t('List of Players')}
        </div>
        {/*<Link to="#">
          <div className={classes.sxl_select_cta_white}>
            Coach of The Season
          </div>
        </Link>*/}
        <div className={classes.sxl_select_cta_black} onClick={() => handleClickNext()}>{t('Next')}</div>
      </div>
    </div>
  );
};

export default SelectPlayersField;
