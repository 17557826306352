import React from 'react'
// components
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next";
import SelectSortBestPlayersBy from '../components/select/SelectSortBestPlayersBy'
import SelectSortPeriod from '../components/select/SelectSortPeriod'
// style
import classes from './BestCoach.module.css';

const BestCoach = ({ bestC, changeFilters, periods, disableAudience }) => {

  const { t } = useTranslation();

  React.useEffect(() => {
    periods && periods[0] && changeFilters("public", periods[0].value)
  }, [])

  return (
    <>
      <div className="sxl_wrapper">
        <div className="sxl_container">
          {/* CONTENT */}
          <div className={classes.sxl_best_holder}>
            <div className={classes.sxl_title}>{t('Best Coach')}</div>
            <div className={classes.sxl_best_coach_wrap}>
              <div className={classes.sxl_best_coaches}>
                <img src={require("../img/field_1.png")} alt="field bg" className={classes.sxl_field_bg} />
                <img src={require("../img/field_small.png")} alt="field bg" className={classes.sxl_field_bg_small} />

                {/* sort period */}
                <div className={classes.sxl_select_holder_period}>
                  <SelectSortPeriod changeFilters={changeFilters} periods={periods} />
                </div>

                {/* sort */}
                <div className={classes.sxl_select_holder_sort}>
                  <SelectSortBestPlayersBy changeFilters={changeFilters} disabled={disableAudience} />
                </div>

                <div className={classes.sxl_selected_coach}>

                  <div className={classes.sxl_best_coach}>
                    <div className={classes.sxl_coach_item}>
                      <div className={classes.sxl_coach_img}>
                        {bestC && bestC[1] && <img className={classes.sxl_pl_img} src={bestC[1].image ? `${process.env.REACT_APP_API}` + bestC[1].image?.formats?.thumbnail?.url : bestC[1].image_name} alt='' />}
                        {bestC && bestC[1] && <img className={classes.sxl_ribon} src={require("../img/1st.svg")} alt="1st place" />}
                      </div>
                      {bestC && bestC[1] && <div className={classes.sxl_coach_last_name}>
                        {bestC[1].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_coach_item}>
                      <div className={classes.sxl_coach_img}>
                        {bestC && bestC[0] && <img className={classes.sxl_pl_img} src={bestC[0].image ? `${process.env.REACT_APP_API}` + bestC[0].image?.formats?.thumbnail?.url : bestC[0].image_name} alt='' />}
                        {bestC && bestC[0] && <img className={classes.sxl_ribon} src={require("../img/1st.svg")} alt="1st place" />}
                      </div>
                      {bestC && bestC[0] && <div className={classes.sxl_coach_last_name}>
                        {bestC[0].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_coach_item}>
                      <div className={classes.sxl_coach_img}>
                        {bestC && bestC[2] && <img className={classes.sxl_pl_img} src={bestC[2].image ? `${process.env.REACT_APP_API}` + bestC[2].image?.formats?.thumbnail?.url : bestC[2].image_name} alt='' />}
                        {bestC && bestC[2] && <img className={classes.sxl_ribon} src={require("../img/1st.svg")} alt="1st place" />}
                      </div>
                      {bestC && bestC[2] && <div className={classes.sxl_coach_last_name}>
                        {bestC[2].lastname}
                      </div>}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default BestCoach;