import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { PuffLoader } from 'react-spinners';

//components
import ResultsRules from '../components/ResultsRules'
import Carousel from '../components/ImageSlider/ImageSlider'
import Countdown from '../components/CountDown/CountDown';
import { getCarousel } from '../utils/services'

// style
import classes from './Home.module.css';

import {
  getActivePeriod
} from '../utils/services';

const Home = () => {

  const { t } = useTranslation();
  const [period, setPeriod] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState()

  React.useEffect(() => {
    getActivePeriod().then(res => {
      setPeriod(res)
      if (res && res[0]) {
        getCarousel().then(res => {
          setImages(res)
        }
        )
      }
    })
      .catch(error => setIsLoading(false))
  }, [])

  const vote_until = period && moment(period[0].vote_to).format("MM/DD/YYYY HH:mm")
  const now = moment().format("MM/DD/YYYY HH:mm")
  const isActivePeriod = vote_until && now ? moment(now).isSameOrAfter(vote_until) : false

  return (
    <>
      {isLoading && (
        <div className="spinerClass">
          <PuffLoader
            color={'#A38E60'}
            size={200}
            loading={isLoading}
          />
        </div>
      )}
      <div className="sxl_wrapper_home" style={{ backgroundColor: '#141414' }}>
        <div className="sxl_container">
          <div className={classes.sxl_home_content}>
            <div className={classes.sxl_main_logo}>
              <Link to="/">
                <img className={classes.logo} src={require("../img/logo.png")} alt="SpinsXL logo" />
              </Link>
            </div>
            <div className="carusel">
              <Carousel fetchedImages={images} setIsLoading={setIsLoading} />
            </div>
            <div className={classes.sxl_title_msg}>
              <div style={{ marginBottom: 20 }}>
                <h1>{t('Part1')} <span style={{ color: '#A38E60' }}>{t('Part2')}</span></h1>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ color: '#A38E60' }}>{period && period[0]?.name} {/*t('Part3')*/}</h3>
                    <img className={classes.telemach_logo} src={require("../img/Telemach-logo.png")} alt="Telemach logo" />
                  </div>
                </div>
              </div>
              <p>{t('Your vote you can submit until')} </p>
              {period && period[0] && <Countdown untilDate={moment(period[0].vote_to).format("MM/DD/YYYY HH:mm")} />}
            </div>

            {/* {period && period[0] && <Countdown 
            untilDate={moment(period[0].vote_to).format("MM/DD/YYYY")} 
                        />} */}
            {!isActivePeriod && <div className={classes.sxl_vote_btn}>
              <Link to="vote" onClick={() => window.scrollTo(0, 0)}>
                {t('Vote Now')}
              </Link>
            </div>}
          </div>
        </div>
      </div>
      <div className="sxl_wrapper" style={{ backgroundColor: 'white' }}>
        <div className="sxl_container">
          <div className={classes.sxl_logos_holder} style={{ marginTop: 30 }}>
            {/* <div className={classes.sxl_logos}>
                <img src={require("../img/home-logos/telekomslovenije.svg")} alt="Telekom Slovenije logo" />
                <img src={require("../img/home-logos/NZS_osnovni_lezeci.svg")} alt="NZS logo" />
              </div> */}
            <div className={classes.sxl_logos}>
              <a href='https://www.avto-aktiv.si/' target="_blank" rel="noreferrer">
                <img className={classes.sxl_logo_item} src={require("../img/home-logos/AvtoAktiv.png")} alt="Avto Aktiv logo" />
              </a>
              <a href='https://www.prowellness.si/' target="_blank" rel="noreferrer">
                <img className={classes.sxl_logo_item} src={require("../img/home-logos/ProWelles.png")} alt="Technogym logo" />
              </a>
              <img className={classes.sxl_logo_item} src={require("../img/home-logos/TehnoGym.png")} alt="Pro Wellness logo" />
              <a href='https://www.grandplazahotel.si/' target='_blank'>
              <img className={classes.sxl_logo_item} src={require("../img/home-logos/Logo_Plaza1.jpg")} alt="Grand Plaza Hotel" />
            </a>
            </div>
            <ResultsRules classes={classes} />
          </div>

        </div>
      </div>
    </>
  )
}

export default Home;