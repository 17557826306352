import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getRulesText } from '../utils/services'

const ResultsRules = ({ classes }) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { t } = useTranslation();
    const [rulesText, setRulesText] = useState('')

    useEffect(() => {
        getRulesText().then(res => res && setRulesText(res?.glasovanje))
    }, [])

    return (
        <div className={classes.sxl_pravila}>
            <p style={{ textAlign: 'center' }}><span><Link
                to="/results">{('Trenutno najboljših 11 v 1.SNL 2023/2024')}</Link></span> • <Link to="#"
                    onClick={() => setModalIsOpen(true)}>{t('Pravila glasovanja')}</Link>
            </p>
            <RulesModal classes={classes} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} rulesText={rulesText} />
        </div>
    )
}

export const RulesModal = ({ modalIsOpen, setModalIsOpen, classes, rulesText }) => {

    const { t } = useTranslation();

    return (
        <Modal
            isOpen={modalIsOpen}
            contentLabel={t('Voting rules')}
            className="modalDisclamer"
            style={{
                overlay: {
                    backgroundColor: '#141414',
                    zIndex: 5
                }
            }}
            onRequestClose={() => setModalIsOpen(false)}
        >
            <div
                style={{
                    marginBottom: '50px',
                }}
            >
                <pre
                    style={{
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                        fontSize: '12pt',
                        padding: '2px',
                        lineHeight: 1.6,
                        fontFamily: "Avenir-Roman",
                        color: 'white',
                        backgroundColor: '#141414'
                    }}
                >{rulesText && rulesText}
                </pre>
                <div className={classes.sxl_vote_btn} style={{ paddingTop: 30 }}>
                    <a href="javascript:void(0);"
                        style={{ fontFamily: "Avenir-Black", backgroundColor: '#A38E60' }}
                        onClick={() => {
                            setModalIsOpen(false);
                        }}>
                        {t('Zapri')}
                    </a>
                </div>
                <br />
            </div>
        </Modal>

    )
}

export default ResultsRules;