import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

// components

// style
import classes from './SelectClub.module.css';


const SelectClub = (props) => {

  const {
    selectedClub,
    clubs,
    handleChange,
  } = props;

  const { t } = useTranslation();

  const fetchedTeams = clubs && clubs.map((club) => ({
    value: club.id,
    text: club.name,
    icon: <img src={club?.logo?.formats?.thumbnail?.url ? `${process.env.REACT_APP_API}` + club?.logo?.formats?.thumbnail?.url : `${process.env.REACT_APP_API}` + club?.logo?.url} alt='' height='21px' />
  }))

  const teams = clubs && fetchedTeams && [{ value: 'all', text: t('All Clubs') }, ...fetchedTeams]

  const [selectedOption, setSelectedOption] = useState();

  React.useEffect(() => {
    const text = clubs.filter(item => item.id === selectedClub)

    if (selectedClub === 'all') {
      setSelectedOption({
        value: selectedClub,
        text: t('All Clubs')
      })
    } else {
      setSelectedOption({
        value: selectedClub,
        text: text && text[0].name
      })
    }

  }, [selectedClub])

  // handle onChange event of the dropdown
  const handleSelectChange = e => {
    handleChange("selectedClub", e.value)
  }

  // SELECT STYLES
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // background: state.isSelected ? 'linear-gradient(to left, #73b76b40 , transparent)' : 'transparent',
      background: '#141414',
      color: 'white',
      padding: '9px 15px 9px 0',
      fontSize: 16,
      lineHeight: '21px',
      fontFamily: 'Avenir-Roman',
      borderBottom: '1px solid #3C3C3C',
      cursor: 'pointer',
      "& img": {
        marginRight: 19,
      },
      "&:last-of-type": {
        borderBottom: 'none',
      },
      "&:hover:after": {
        background: '#73b76b40'
      },
      "&:active": {
        background: 'transparent',
      },
      "&:after": {
        content: '""',
        width: 7,
        height: 7,
        background: state.isSelected ? '#73B76B' : 'transaprent',
        borderRadius: '50%',
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%)'
      }
    }),
    control: (base, state) => ({
      border: '1px solid #3C3C3C',
      borderColor: state.selectProps.menuIsOpen
        ? 'grey'
        : 'grey',
      transition: 'border .3s ease',
      borderRadius: state.selectProps.menuIsOpen
        ? '22px 22px 0 0 '
        : 22,
      borderBottom: state.selectProps.menuIsOpen
        ? '1px solid grey'
        : '1px solid grey',
      display: 'flex',
      width: '100%',
      cursor: 'pointer',
      background: '#54585c69',
      zIndex: state.selectProps.menuIsOpen ? '100' : '1',
    }),
    indicatorSeparator: () => { },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform .3s ease',
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '8px 15px'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'white',
        width: '100%',
        fontSize: 16,
        lineHeight: '21px',
        fontFamily: 'Avenir-Roman',
        "& img": {
          marginRight: 19,
        },
      };
    },
    container: (provided, state) => ({
      width: '100%',
      "&:after": {
        content: '""',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: state.selectProps.menuIsOpen ? '#00000080' : 'transaprent',
        zIndex: state.selectProps.menuIsOpen ? '99' : '-1',
        display: state.selectProps.menuIsOpen ? 'block' : 'none',
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      borderRadius: '0 0 22px 22px',
      border: '1px solid #3C3C3C',
      borderTopColor: 'transparent',
      boxShadow: 'none',
      paddingLeft: '15px',
      overflow: 'hidden',
      background: '#141414',
      zIndex: state.selectProps.menuIsOpen ? '100' : '1',
    }),
    menuList: (base) => ({
      ...base,
      height: 'auto',
      maxHeight: 'auto',
      minHeight: "auto",
      padding: 0
    }),
  }

  return (
    <>
      <div className={classes.sxl_select_club_holder}>
        <Select
          styles={customStyles}
          placeholder={t('All Clubs')}
          value={selectedOption}
          defaultValue={selectedOption}
          options={teams}
          onChange={handleSelectChange}
          isSearchable={false}
          blurInputOnSelect
          closeMenuOnSelect
          // menuIsOpen 
          getOptionLabel={e => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {e.icon}
              <span>{e.text}</span>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default SelectClub;