import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      scroll.scrollToTop({
        duration: 0,
        smooth: true
      })
    });
    return () => {
      unlisten();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
