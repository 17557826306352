import React, { useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getAppStatus } from '../utils/services';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const getMessage = (pathname) => {
    if (pathname === '/paused')
        return {
            title: 'Voting is currently paused',
            linkText: 'Try Again Later',
            redirectTo: '/',
        };
    if (pathname === '/closed') {
        return {
            title:
                'Voting is currently closed, final results will be published soon',
            linkText: 'Go To Results',
            redirectTo: '/results',
        };
    }
    return {
        title: 'Page not found',
        linkText: 'Go To Home Page',
        redirectTo: '/',
    };
};

const NotFoundPage = () => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (location.pathname === '/paused') {
            getAppStatus()
                .then((res) => {
                    if (res === 'Open') {
                        toast.warning(t('Voting is currently open, you can submit your vote'));
                        setTimeout(() => history.replace('/'), 2200);
                    }
                    if (res === 'Published' || res === 'Closed') {
                        toast.warning(t('Voting is not paused anymore, check out current results'));
                        setTimeout(() => history.replace('/results'), 2200);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);
    const data = getMessage(location.pathname);
    return (
        <div className="notFound">
            <h2>{t(data.title)}</h2>
            <Link to={data.redirectTo}>
                <h3>{t(data.linkText)}</h3>
            </Link>
        </div>
    );
};

export default NotFoundPage;
