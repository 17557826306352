import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

// style
import classes from './SelectSortPlayersBy.module.css';

import {
  useLocation
} from "react-router-dom";


// services
import {
  getParams
} from '../../utils/services';


const SelectSortPlayersBy = (props) => {

  const {
    handleChange,
    selectedPosition,
    sortedParams,
    bestPlayerSelecting
  } = props

  const { t } = useTranslation();
  let location = useLocation();
  const [sortPlayersBy, setSortPlayersBy] = useState()
  const [selectedOption, setSelectedOption] = useState();
  const [loadingParams, setLoadingParams] = useState(location?.state)

  // handle onChange event of the dropdown
  const handleChangeSelect = e => {
    handleChange('sort', e?.value)
    setSelectedOption(e);
  }

  React.useEffect(() => {
    if (sortPlayersBy && sortPlayersBy[0]) {
      let foundIndex = 0
      if (loadingParams && !bestPlayerSelecting) {
        sortPlayersBy.forEach((item, index) => {
          const search = 'stat.' + loadingParams.params
          if (item.value === search) {
            foundIndex = index
          }
        })
        setSelectedOption(sortPlayersBy[foundIndex])
        setLoadingParams()
      } else setSelectedOption(sortPlayersBy[0])

    }
  }, [sortPlayersBy])

  React.useEffect(() => {
    if (!loadingParams) {
      const options = sortedParams(selectedPosition, 'forFilter')
      setSortPlayersBy([...options])
    }
  }, [selectedPosition])

  React.useEffect(() => {
    if (loadingParams) {
      getParams().then(res => {

        const options = [{
          value: 'stat.spins_index',
          text: 'Spins index'
        }]

        res && res[0] && res.forEach((item) => {
          if (item?.is_filter) {
            if (item?.position.includes(selectedPosition)) {
              options.push({
                value: `stat.${item?.param_name}`,
                text: item?.param_text,
              })
            }
          }
        })
        setLoadingParams()
        setSortPlayersBy([...options])
      })
    }
  }, [])

  // SELECT STYLES
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: '#141414',
      color: 'white',
      padding: '9px 15px 9px 0',
      fontSize: 16,
      lineHeight: '21px',
      fontFamily: 'Avenir-Roman',
      borderBottom: '1px solid #3C3C3C',
      cursor: 'pointer',
      "&:last-of-type": {
        borderBottom: 'none',
      },
      "&:hover:after": {
        background: '#73b76b40'
      },
      "&:active": {
        background: 'transparent',
      },
      "&:after": {
        content: '""',
        width: 7,
        height: 7,
        background: state.isSelected ? '#73B76B' : 'transaprent',
        borderRadius: '50%',
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%)'
      }
    }),
    control: (base, state) => ({
      transition: 'border .3s ease',
      display: 'flex',
      width: '100%',
      cursor: 'pointer',
      background: '#141414',
      zIndex: state.selectProps.menuIsOpen ? '98' : '',
    }),
    indicatorSeparator: () => { },
    valueContainer: (provided) => ({
      ...provided,
      padding: '8px 0 8px 15px'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'white',
        width: '100%',
        fontSize: 16,
        lineHeight: '21px',
        fontFamily: 'Avenir-Book'
      };
    },
    container: (provided, state) => ({
      width: '100%',
      "&:after": {
        content: '""',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: state.selectProps.menuIsOpen ? '#00000080' : 'transaprent',
        zIndex: state.selectProps.menuIsOpen ? '99' : '-1',
        display: state.selectProps.menuIsOpen ? 'block' : 'none',
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 190,
      marginTop: 10,
      borderRadius: '22px',
      boxShadow: 'none',
      paddingLeft: '15px',
      background: '#141414',
      right: 0,
      zIndex: state.selectProps.menuIsOpen ? '100' : '',
      "&:after": {
        content: '""',
        position: 'absolute',
        right: '40px',
        top: '-13px',
        width: 0,
        height: 0,
        borderLeft: '13px solid transparent',
        borderRight: '13px solid transparent',
        borderBottom: '13px solid #fff',
        clear: 'both',
      }
    }),
    menuList: (base) => ({
      ...base,
      height: 'auto',
      maxHeight: 'auto',
      minHeight: "auto",
      padding: 0
    }),
  }

  return (
    <>
      <div className={classes.sxl_select_club_holder}>
        {sortPlayersBy && <Select
          styles={customStyles}
          placeholder={t("Sort by")}
          value={selectedOption && selectedOption}
          defaultValue={sortPlayersBy && sortPlayersBy[0]}
          options={sortPlayersBy && sortPlayersBy}
          onChange={handleChangeSelect}
          noOptionsMessage={() => t("No parameters")}
          isSearchable={false}
          blurInputOnSelect
          closeMenuOnSelect
          // menuIsOpen 
          getOptionLabel={e => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{e.text}</span>
            </div>
          )}
        />}
      </div>
    </>
  )
}

export default SelectSortPlayersBy;