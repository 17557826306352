import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// styles
import classes from './Navigation.module.css';



const Navigation = (props) => {

  const { t } = useTranslation();

  return (
    <div className="sxl_wrapper">
      <div className="sxl_container">
        <div className={classes.sxl_select_players_nav}>
          <div className={classes.sxl_main_logo}>
            <Link to="/">
              <img src={require("../img/logo.png")} alt="SpinsXL logo" />
            </Link>
          </div>
          <nav className={classes.sxl_nav}>
            <ul>
              <li>
                <Link className={props?.selected?.bestplayers11 ? classes.active : undefined} to='#' onClick={() => props.setSelected({
                  bestcoach: false,
                  bestplayer: false,
                  bestplayers11: true
                })}>{t('Best 11')}</Link>
              </li>
              <li>
                <Link className={props?.selected?.bestplayer ? classes.active : undefined} to='#' onClick={() => props.setSelected({
                  bestcoach: false,
                  bestplayer: true,
                  bestplayers11: false
                })}>{t('Best Player')}</Link>
              </li>
              <li>
                <Link className={props?.selected?.bestcoach ? classes.active : undefined} to='#' onClick={() => props.setSelected({
                  bestcoach: true,
                  bestplayer: false,
                  bestplayers11: false
                })}>{t('Best Coach')}</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Navigation;