import React, { useEffect, useState } from "react";

// components
import PlayerInfo from "./PlayerInfo";
import CoachInfo from './CoachInfo'
import SelectClub from "./select/SelectClub";
import SelectPlayerPosition from "./select/SelectPlayerPosition";
import SelectSortPlayersBy from "./select/SelectSortPlayersBy";
import SelectCoach from "./select/SelectCoach";
import SelectSortCoachBy from "./select/SelectSortCoachBy";
import ListItem from "./ListItem"
import { useTranslation } from "react-i18next";
import ReactTooltip from 'react-tooltip';

// styles
import classes from "./PlayersList.module.css";

const PlayersList = (props) => {

  const {
    selectedClub,
    clubs,
    selectedPosition,
    positionOptions,
    bestPlayerSelecting,
    bestCoachSelecting,
    displayPlayers,
    addToTop,
    removePlayer,
    handleChange,
    show,
    onCloseRight,
    step,
    sortedParams,
    sortFilter,
    orderby,
    showInfos,
    setShowInfos,
    paramsText
  } = props;

  const { t } = useTranslation();
  const [carouselP, setCarouselP] = useState()

  useEffect(() => {
    paramsText && setCarouselP()
  }, [selectedPosition])

  useEffect(() => {
    setCarouselP(paramsText)
  }, [])

  // close mobile select players right side
  const handleCloseClick = (e) => {
    onCloseRight();
  };

  // data for model dialog
  const [playersMethods, setPlayersMethods] = useState({
    playersData: null,
    added: null,
    setAdded: null,
    setRemoving: null
  })

  const showPlayerInfosClick = (e) => {
    setShowInfos(true);
  }

  const closePlayerInfosClick = (e) => {
    setShowInfos(false);
  }

  return (
    <div
      className={`${classes.sxl_select_players_right} ${show ? classes.sxl_show_right : ""
        }`}
    >
      <div className={classes.sxl_select_players_right_title}>
        <div className={classes.sxl_back_holder} onClick={handleCloseClick}>
          <img src={require("../img/arrow_left.svg")} alt="" /> {t('Back')}
        </div>
        {step.position === 1 && t(`Choose Players`)}
        {step.position === 2 && t(`Choose Coach`)}
        {step.position === 3 && t(`Choose best player`)}
        <div className={classes.sxl_back_stats_icon}>
          <img src={require("../img/diagram.svg")} alt="" />
        </div>
      </div>

      <div className={classes.sxl_select_dropdowns}>
        <div className={classes.sxl_select_holder}>
          <SelectClub clubs={clubs} selectedClub={selectedClub} handleChange={handleChange} />
        </div>
        <div className={classes.sxl_select_holder}>
          {/*step.position === 2 ? <SelectCoach selectedPosition={selectedPosition} positionOptions={positionOptions} handleChange={handleChange} /> :
            <SelectPlayerPosition selectedPosition={selectedPosition} positionOptions={positionOptions} handleChange={handleChange} />*/}
          <SelectPlayerPosition bestCoachSelecting={bestCoachSelecting} selectedPosition={selectedPosition} positionOptions={positionOptions} handleChange={handleChange} />
        </div>
      </div>

      <div className={classes.sxl_select_search}>
        <div className="sxl_input_holder">
          <input
            type="text"
            placeholder={t("Search")}
            onChange={(e) => {
              const textLenght = e.target.value.length
              if (textLenght > 2) handleChange("searchPlayer", e.target.value)
              if (textLenght === 0) handleChange("searchPlayer", e.target.value)
            }

            } />
          <img src={require("../img/search.svg")} alt="search icon" />
        </div>
      </div>
      <div className={classes.sxl_select_allplayers_sort}>
        <div className={classes.sxl_select_allplayers}>
          {step.position === 1 && t(`Players`)}
          {step.position === 2 && t(`Coaches`)}
          {step.position === 3 && t(`Players`)}
        </div>
        <div className={classes.sxl_select_sort}>
          <div className={classes.sxl_select_holder_sort}>
            {step.position !== 2 && selectedPosition !== "all" && <SelectSortPlayersBy handleChange={handleChange} selectedPosition={selectedPosition} sortedParams={sortedParams} bestPlayerSelecting={bestPlayerSelecting} />}
            {selectedPosition === "all" && carouselP && <p className={classes.params_text_carousel}>{carouselP}</p>}
            {selectedPosition === "all" && !carouselP && <>
              <div className={classes.params_text_carousel}>
                <div>Spins index</div>
                <div style={{ position: 'absolute', left: 85, top: -10 }}>
                  <span style={{ border: 'solid', borderWidth: 1, borderRadius: 25, paddingLeft: 4, paddingRight: 4, color: '#A38E60', fontSize: 10, marginLeft: 3 }} data-for="spinsHome" data-tip={t('spins_index_tooltip1')}>
                    ?</span>

                </div>
                <ReactTooltip multiline={true} id="spinsHome" />
              </div>
            </>}
          </div>
        </div>
      </div>

      {/* PLAYERS TABLE */}
      <div className={classes.sxl_select_players_table}>
        <table cellSpacing="0" cellPadding="0" className="sxl_table">
          <tbody>
            <tr>
              <th align="left">{t('Name')}</th>
              {!bestCoachSelecting && <th align="center" style={{ cursor: "pointer" }} onClick={() => {
                if (orderby === "ASC") handleChange("orderby", "DESC")
                if (orderby === "DESC") handleChange("orderby", "ASC")
              }}>{t('Value')} {orderby === "ASC" ? <span >⬆<b style={{ color: 'grey' }}>⬇</b></span> : <span><b style={{ color: 'grey' }}>⬆</b>⬇</span>}</th>}
              <th align="center">{t('Club')}</th>
              <th align="center">{t('Vote')}</th>
            </tr>
            {displayPlayers.length > 0 && displayPlayers.map(playerData => {

              if (!bestCoachSelecting) {
                if (playerData.position !== "C") return (<ListItem
                  key={playerData.id}
                  player={playerData}
                  addToTop={() => addToTop(playerData)}
                  removeFromTop={() => removePlayer(playerData)}
                  isAdded={playerData.isSelected}
                  isBestPlayerSelected={playerData.isBestPlayerSelected}
                  bestPlayerSelecting={bestPlayerSelecting}
                  bestCoachSelecting={bestCoachSelecting}
                  showPlayerInfosClick={showPlayerInfosClick}
                  setPlayersMethods={setPlayersMethods}
                  sortFilter={sortFilter}
                />)
              }

              if (bestCoachSelecting) {
                if (playerData.position === "C") return (<ListItem
                  key={playerData.id}
                  player={playerData}
                  addToTop={() => addToTop(playerData)}
                  removeFromTop={() => removePlayer(playerData)}
                  isAdded={playerData.isSelected}
                  bestPlayerSelecting={bestPlayerSelecting}
                  bestCoachSelecting={bestCoachSelecting}
                  showPlayerInfosClick={showPlayerInfosClick}
                  setPlayersMethods={setPlayersMethods}
                  sortFilter={sortFilter}
                />)
              }
            })}
          </tbody>
        </table>
      </div>
      <div className={classes.sxl_small_add_vote_btn_holder}>
        <div className={classes.sxl_small_add_vote_btn} style={{ cursor: 'pointer' }} onClick={handleCloseClick}>{t('Back')}</div>
      </div>

      {step.position !== 2 &&
        <PlayerInfo
          onCloseInfos={() => closePlayerInfosClick()}
          show={showInfos}
          step={step}
          sortedParams={sortedParams}
          playersMethods={playersMethods}
          addToTop={() => addToTop(playersMethods.playersData)}
          removeFromTop={() => removePlayer(playersMethods.playersData)} />}
    </div>
  );
};

export default PlayersList;
