import React from 'react'
// components
import Footer from "../components/Footer"
import SelectSortBestPlayersBy from '../components/select/SelectSortBestPlayersBy'
import SelectSortPeriod from '../components/select/SelectSortPeriod'
import { useTranslation } from "react-i18next";
// style
import classes from './BestPlayersRightNow.module.css';

const BestPlayersRightNow = ({ top11, changeFilters, periods, disableAudience }) => {

  const { t } = useTranslation();

  React.useEffect(() => {
    periods && periods[0] && changeFilters("public", periods[0].value)
  }, [])

  return (
    <>
      <div className="sxl_wrapper">
        <div className="sxl_container">
          {/* CONTENT */}
          <div className={classes.sxl_best_holder}>
            <div className={classes.sxl_title}>{t('Best 11 Right Now')}</div>
            <div className={classes.sxl_best_11_content}>
              <div className={classes.sxl_best_11_players}>
                <img src={require("../img/field_1.png")} alt="field bg" className={classes.sxl_field_bg} />
                <img src={require("../img/field_small.png")} alt="field bg" className={classes.sxl_field_bg_small} />

                {/* sort period */}
                <div className={classes.sxl_select_holder_period}>
                  <SelectSortPeriod changeFilters={changeFilters} periods={periods} />
                </div>

                {/* sort */}
                <div className={classes.sxl_select_holder_sort}>
                  <SelectSortBestPlayersBy changeFilters={changeFilters} disabled={disableAudience}/>
                </div>

                <div className={classes.sxl_selected_players}>

                  {/* GK */}
                  <div className={classes.sxl_selected_gk}>
                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[0].image ? `${process.env.REACT_APP_API}` + top11[0].image?.formats?.thumbnail?.url : top11[0].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[0].lastname}
                      </div>}
                    </div>
                  </div>

                  {/* DEF */}
                  <div className={classes.sxl_selected_def}>
                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[1].image ? `${process.env.REACT_APP_API}` + top11[1].image?.formats?.thumbnail?.url : top11[1].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[1].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[2].image ? `${process.env.REACT_APP_API}` + top11[2].image?.formats?.thumbnail?.url : top11[2].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[2].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[3].image ? `${process.env.REACT_APP_API}` + top11[3].image?.formats?.thumbnail?.url : top11[3].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[3].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[4].image ? `${process.env.REACT_APP_API}` + top11[4].image?.formats?.thumbnail?.url : top11[4].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[4].lastname}
                      </div>}
                    </div>
                  </div>

                  {/* MID */}
                  <div className={classes.sxl_selected_mid}>
                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[5].image ? `${process.env.REACT_APP_API}` + top11[5].image?.formats?.thumbnail?.url : top11[5].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[5].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[6].image ? `${process.env.REACT_APP_API}` + top11[6].image?.formats?.thumbnail?.url : top11[6].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[6].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[7].image ? `${process.env.REACT_APP_API}` + top11[7].image?.formats?.thumbnail?.url : top11[7].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[7].lastname}
                      </div>}
                    </div>
                  </div>

                  {/* ATT */}
                  <div className={classes.sxl_selected_att}>
                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[1].image ? `${process.env.REACT_APP_API}` + top11[8].image?.formats?.thumbnail?.url : top11[8].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[8].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[9].image ? `${process.env.REACT_APP_API}` + top11[9].image?.formats?.thumbnail?.url : top11[9].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[9].lastname}
                      </div>}
                    </div>

                    <div className={classes.sxl_player_item}>
                      <div className={classes.sxl_player_img}>
                        {top11 && top11[0] && <img src={top11[10].image ? `${process.env.REACT_APP_API}` + top11[10].image?.formats?.thumbnail?.url : top11[10].image_name} alt='' />}
                      </div>
                      {top11 && top11[0] && <div className={classes.sxl_player_last_name}>
                        {top11[10].lastname}
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default BestPlayersRightNow;