import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// components
import SelectCoachField from "../components/SelectCoachField";
import SelectPlayersField from "../components/SelectPlayersField";
import SelectBestPlayer from "../components/SelectBestPlayerField"
import PlayersList from "../components/PlayersList";
import Footer from "../components/Footer";
import NavigationVote from "../components/NavigationVote";

// services
import {
    canVote as canVoteService,
    getAppStatus,
    getClubs,
    getPlayers,
    getUser,
    sendVotes,
    getParams
} from '../utils/services';

// utils
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import debounce from 'debounce';
import { PuffLoader } from 'react-spinners';
import { useTranslation } from "react-i18next";
import {
    useLocation
} from "react-router-dom";

// style
import classes from './Vote.module.css';

const VotePage = (props) => {
    let location = useLocation();

    const { t } = useTranslation();
    const [step, setStep] = React.useState({
        position: 1
    })
    const [searchFilters, setSearchFilters] = useState({
        selectedClub: 'all',
        selectedPosition: location?.state?.position ? location?.state?.position : 'all',
        searchPlayer: '',
        votingHash: '',
        sort: location?.state?.params ? 'stat.' + location.state.params : 'stat.instat_index',
        orderby: "DESC"
    });
    const [otherState, setOtherState] = useState({
        positionOptions: [
            { value: 'all', text: t('All Positions') },
            { value: 'G', text: 'Vratar' },
            { value: 'D', text: 'Branilec' },
            { value: 'M', text: 'Vezni' },
            { value: 'F', text: 'Napadalec' },
            { value: 'C', text: 'Trener' },
        ],
        clubs: [],
        top11: { D: [], T: [], C: [], F: [], M: [], G: [] },
        //  displayPlayers: { D: [], T: [], C: [], F: [], M: [], G: [] },
        displayPlayers: []
    });
    const [selecting, setSelecting] = useState({
        bestCoachSelecting: false,
        bestPlayerSelecting: false,
        playerSelecting: true,
    });
    const [filterParams, setFilterParams] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [canVote, setCanVote] = useState(false);
    const [showRight, setShowRight] = useState(false);
    const { hash } = useParams();
    const history = useHistory();
    const [showInfos, setShowInfos] = useState(false);

    const noVoteMessage = t('noVoteMessage')

    React.useEffect(() => {
        getParams().then(res => setFilterParams(res))
        getAppStatus()
            .then((res) => {
                res === 'Closed' && history.replace('/results?redirect=true');
                res === 'Paused' && history.replace('/paused');
                res === 'Published' && history.replace('/results');
            })
            .catch((error) => {
                toast.error(error.message);
                setIsLoading(false);
            });
        if (typeof hash !== 'undefined') {
            _getUser();
        } else {
            canUserVote();
        }
    }, []);

    React.useEffect(() => {
        canVote && _getPlayers();
    }, [searchFilters]);

    React.useEffect(() => {
        if (location?.state?.params) {
            openRight()
        }
    }, [location]);

    const confirmVotingAs = () => {
        setCanVote(true);
        setSearchFilters((prevState) => ({
            ...prevState,
            votingHash: hash,
        }));
        _getClubs();
    };

    const _getClubs = () => {
        getClubs(hash)
            .then((res) => {
                setOtherState((prevState) => ({ ...prevState, clubs: res }));
            })
            .catch((error) => {
                toast.error(error.message);
                setIsLoading(false);
            });
    };

    const canUserVote = () => {
        canVoteService(hash)
            .then((res) => {
                setCanVote(res);
                if (res) {
                    setSearchFilters((prevState) => ({
                        ...prevState,
                        votingHash: '',
                    }));
                    _getClubs();
                } else {
                    toast.warning(noVoteMessage);
                }
            })
            .catch((error) => {
                if (error.message === 'User already voted today, please try again tomorrow.') toast.warning(t('User already voted today, please try again tomorrow'))
                else toast.warning(error.message);
                history.replace("/")
                setCanVote(false);
            });
    }

    const _getPlayers = debounce(() => {
        setIsLoading(true);
        const { searchPlayer, selectedClub, selectedPosition, sort, orderby } = searchFilters;
        getPlayers(searchPlayer, selectedClub, selectedPosition, sort, hash, orderby)
            .then((res) => {
                // const players = {
                //     G: [],
                //     D: [],
                //     M: [],
                //     F: [],
                //     C: [],
                //     T: [],
                // };
                const players = []
                res.forEach((player) => {
                    player.isSelected =
                        player.position !== 'J' &&
                        otherState.top11[player.position].some(
                            (o) => o.id === player.id
                        );
                    player.position !== 'J' &&
                        //  players[player.position].push(player);
                        players.push(player);
                    // added isBestPlayerSelected
                    player.isBestPlayerSelected =
                        player.position !== 'J' &&
                        otherState.top11['T'].some(
                            (o) => o.id === player.id
                        );
                });
                setIsLoading(false);
                setOtherState((prevState) => ({
                    ...prevState,
                    displayPlayers: players,
                }));
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.message);
            });
    }, 500);

    const _getUser = () => {
        getUser(hash)
            .then((res) => {
                if (!res.voted) { //vos club log
                    setIsLoading(false)
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div>
                                    <div className="submitted_vote confirm_identity">
                                        <h1>SPINS XI</h1>
                                        <p>
                                            {t('You vote as')}: {res.firstname} {' '}
                                            {res.lastname}
                                        </p>
                                        <div className="buttonsHolder">
                                            <button
                                                onClick={() => {
                                                    confirmVotingAs();
                                                    onClose();
                                                }}
                                                className="alertButton"
                                            >
                                                Yes
                                            </button>
                                            <button
                                                onClick={() => {
                                                    toast.error(t('Check your voting ID'));
                                                    onClose();
                                                    history.replace("/")
                                                }}
                                                className="alertButton"
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        },
                        onClickOutside: () => toast.error(t('Check your voting ID')) & history.replace("/"),
                    });
                } else {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div>
                                    <div className="submitted_vote">
                                        <h1>SPINS XI</h1>
                                        <p style={{ paddingLeft: 10, paddingRight: 10 }}>{t(
                                            'You have already voted, if not, check your voting ID',
                                        )} </p>
                                    </div>
                                </div>
                            );
                        },
                    });
                    history.replace('/results');
                    setIsLoading(false);
                    setCanVote(false);
                }
            })
            .catch((error) => {
                setCanVote(false);
                setIsLoading(true);
                toast.error(t('Check your voting ID'));
            });
    };

    const addToTop = (player) => {
        try {
            const top11 = otherState.top11;
            let error = false;
            let addedBestPlayerOrCoach = false;
            if (selecting.bestPlayerSelecting) {
                if (top11.T.length > 0) {
                    error = true;
                    toast.warning(t('This position is already full'))
                } else {
                    top11.T = [];
                    top11.T[0] = player;
                }
            } else if (player.position === 'C') {
                if (top11.C.length > 0) {
                    error = true;
                    toast.warning(t('This position is already full'))
                }
                else {
                    top11.C = [];
                    top11.C[0] = player;
                }
            } else {
                switch (player.position) {
                    case 'G':
                        if (top11.G.length > 0) {
                            error = true;
                        }
                        break;
                    case 'D':
                        if (top11.D.length > 3) {
                            error = true;
                        }
                        break;
                    case 'M':
                        if (top11.M.length > 2) {
                            error = true;
                        }
                        break;
                    case 'F':
                        if (top11.F.length > 2) {
                            error = true;
                        }
                        break;
                    case 'T':
                        if (top11.T.length > 0) {
                            error = true;
                        }
                        break;
                    case 'C':
                        if (top11.C.length > 0) {
                            error = true;
                        }
                        break;
                    default:
                }

                if (!error) {
                    if (player.position !== 'J') {
                        top11[player.position].push(player)
                    }
                } else {
                    toast.warning(t('This position is already full'));
                }
            }
            setOtherState((prevState) => ({ ...prevState, top11 }));
            /* setSelecting({
                 playerSelecting: false,
                 bestPlayerSelecting: false,
                 bestCoachSelecting: false,
             }); */
            /*   player.position === 'C' &&
                   setSearchFilters((prevState) => ({
                       ...prevState,
                       selectedPosition: 'all',
                   })); */
            return error || addedBestPlayerOrCoach;
        } catch (error) {
            toast.error(error.message);
            return false;
        }
    };

    const removePlayer = (player) => {
        const top11 = otherState.top11;
        if (selecting.bestPlayerSelecting) {
            top11['T'] = top11['T'].filter(
                (p) => p.id !== player.id,
            );
        } else
            if (player.position !== 'J') {
                top11[player.position] = top11[player.position].filter(
                    (p) => p.id !== player.id,
                );
            }
        player.isSelected = !player.isSelected;
        if (selecting.bestPlayerSelecting) player.isBestPlayerSelected = !player.isBestPlayerSelected
        setOtherState((prevState) => ({ ...prevState, top11 }));
        return player.isSelected;
    };

    const handleChange = (name, value) => {
        if (name === 'sort' && value === 'stat.spins_index') setSearchFilters((prevState) => ({ ...prevState, [name]: value, sort: 'stat.instat_index' }))
        else if (name === 'selectedPosition' && value === 'all') setSearchFilters((prevState) => ({ ...prevState, [name]: value, sort: 'stat.instat_index' }))
        else setSearchFilters((prevState) => ({ ...prevState, [name]: value }));
        name === 'selectedPosition' &&
            value === 'C' &&
            setSelecting((prevState) => ({
                ...prevState,
                bestCoachSelecting: true,
            }));
    };

    const onPlayerClick = (position, player) => {
        showInfos && setShowInfos(false)
        setIsLoading(true);
        const defaultParamsSort = sortedParams(position, 'forFilter')

        if (!player) {
            setSelecting((prevState) => ({
                ...prevState,
                playerSelecting: true,
            }));
        } else removePlayer(player);

        setSearchFilters((prevState) => ({
            ...prevState,
            selectedPosition: position === "T" ? "all" : position,
            sort: position !== "C" && defaultParamsSort[0]?.value
        }));

    };

    const vote = debounce(() => {
        setIsLoading(true);
        sendVotes(otherState.top11, hash)
            .then((res) => {
                setIsLoading(false);
                history.push({
                    pathname: '/successfully-submitted',
                    state: {
                        voteID: res.id,
                        vote_hash: hash
                    }
                })
            })
            .catch((error) => {
                toast.error(error.message);
                setIsLoading(false);
            });
    }, 200);

    const sortedParams = (selectedPosition, isFilter) => {
        const options = [{
            value: 'stat.spins_index',
            text: 'Spins index'
        }]
        filterParams && filterParams[0] && filterParams.forEach((item) => {
            if (isFilter === 'forFilter') {
                if (item?.is_filter) {
                    if (item?.position.includes(selectedPosition)) {
                        options.push({
                            value: `stat.${item?.param_name}`,
                            text: item?.param_text,
                        })
                    }
                }
            }
            else {
                if (item?.position.includes(selectedPosition)) {
                    options.push(item)
                }

            }
        })
        return options
    }

    const openRight = () => {
        setShowRight(true);
    }

    const closeRight = () => {
        setShowRight(false);
    }

    return (
        <>
            {isLoading && (
                <div className="spinerClass">
                    <PuffLoader
                        color={'#A38E60'}
                        size={200}
                        loading={isLoading}
                    />
                </div>
            )}
            <div className="sxl_wrapper">
                <div className="sxl_container">

                    {/* TOP NAV */}
                    <NavigationVote top11={otherState.top11} step={step} setStep={setStep} classes={classes} setSelecting={setSelecting} />

                    {/* CONTENT */}
                    <div className={classes.sxl_select_coach_content}>

                        {/* LEFT */}
                        {step.position === 1 && <SelectPlayersField setSelecting={setSelecting} onPlayerClick={onPlayerClick} otherState={otherState} onOpenRight={() => openRight()} setStep={setStep} />}
                        {step.position === 2 && <SelectCoachField setSelecting={setSelecting} onPlayerClick={onPlayerClick} otherState={otherState} onOpenRight={() => openRight()} setStep={setStep} />}
                        {step.position === 3 && <SelectBestPlayer vote={vote} setSelecting={setSelecting} onPlayerClick={onPlayerClick} otherState={otherState} onOpenRight={() => openRight()} />}

                        {/* RIGHT */}
                        <PlayersList
                            selectedClub={searchFilters.selectedClub}
                            clubs={otherState.clubs}
                            selectedPosition={searchFilters.selectedPosition}
                            positionOptions={otherState.positionOptions}
                            bestCoachSelecting={selecting.bestCoachSelecting}
                            bestPlayerSelecting={selecting.bestPlayerSelecting}
                            displayPlayers={otherState.displayPlayers}
                            addToTop={addToTop}
                            removePlayer={removePlayer}
                            handleChange={handleChange}
                            onCloseRight={() => closeRight()}
                            show={showRight}
                            step={step}
                            filterParams={filterParams}
                            sortedParams={sortedParams}
                            sortFilter={searchFilters.sort}
                            orderby={searchFilters.orderby}
                            showInfos={showInfos}
                            setShowInfos={setShowInfos}
                            paramsText={location?.state?.param_text}
                        />
                    </div>

                    {/* FOOTER */}
                    <Footer />

                </div>
            </div>
        </>
    )
}

export default VotePage;
