const sr = {
    'Welcome to SPINxi Platform': 'asdasd1',
    'Prijavi se v napredni tečaj za nogometneg analitika': 'Register for our advanced football analyst course.',
    "PREBERI VEČ": "READ MORE",
    "POGLEJ VEČ": "VIEW MORE",
    "Prvi napredni tečaj za nogometneg analitaka na Balkanu zajema štiri segmente andaecepel erferum fugitia sum dolorro et int, quasimos andam, se rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio": "Prvi napredni tečaj za nogometneg analitaka na Balkanu zajema štiri segmente andaecepel erferum fugitia sum dolorro et int, quasimos andam, se rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio.",
    "Education of analytic staff department": "The roles of analyst and scout",
    "Hardware + Software": "Hardware + Software ",
    "Individual and group performance analysis": "Individual and group performance analysis",
    "Drawing tools + Mobile Apps": "Drawing tools + Mobile Apps",
    "Team performance analysis": "Team performance analysis",
    "Video platform": "InStat video platform",
    "Match Analysis": "Match Analysis",
    "Animation + Photos": "Animation + Photos",
    "Scouting - Youth Selections - Set Pieces": "Scouting - Youth Selections - Set Pieces",
    "Graphics drawing": "Graphics drawing",
    "Reports & Final Exam": "Reports - Final Exam",
    "Live Coding + Date Base + Shearing apps": "Live Coding + Datebase + Sharing app",
    "GRAFIČNA ANALIZA": "GRAFIČKA ANALIZA",
    "Andaecepel erferšćčum fugitia sum dolorro et int, quasimos andam": "Andaecepel erferšćčum fugitia sum dolorro et int, quasimos andam.",
    "POROČILA": "POROČILA",
    "Andaecepel erferšćčum fugitia sum dolorro": "Andaecepel erferšćčum fugitia sum dolorro.",
    "ANALIZA NASPROTNIKA": 'ANALIZA PROTIVNIKA',
    "SCOUTING": "SCOUTING",
    "Zlati pokrovitelj": "Gold sponsor:",
    'Srebrni pokrovitelj': 'Silver sponsor:',
    'Bronasti pokrovitelj': 'Bronze sponsor:',
    'AFI - Certifikat za naprednega nogometnega anlitika': 'AFI certificate for advanced football analysts',
    'Cena trimesečnega on-line tečaj znaša': 'The price of our 6-month online course amounts to:',
    '2000 EUR': '2000 EUR',
    'Ponujamo popust za začetno generacijo nogometnih analitikov (število mest je omejeno)': 'We are offering a discount rate for the first generation of football analysts (spaces are limited):',
    '1500 EUR': '1500 EUR',
    'PRIJAVI SE': 'REGISTER',
    'Z nami sodelujejo': 'Sa nama sarađuju:',
    'Sledite nam': 'Follow us:',
    'PROGRAM': 'PROGRAMME',
    'PREDAVATELJI': 'PRESENTERS',
    'BLOG': 'NEWS',
    'O NAS': 'ABOUT',
    'Tečaj zajema šest modulov iz področij analitičnih aktivnosti': 'Tečaj zajema šest modulov iz področij analitičnih aktivnosti.',
    'program_graficna_analiza': '"Andaecepel erferšćčum fugitia sum dolorro et int, quasimos andam.se rem ium voloriae pratus qui volore acillentiis solor simperupta venihil modi niet latem volor saped maiose rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio.  maiose rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio.erferšćčum fugitia sum dolorro et int, quasimos andam.se rem ii volore acillentiis solor simperupta venihil modi niet latem volor saped maiose rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio.  maiose rem ium voloriae pratus qui volore lacillentiis solor simperupta venihil modi niet latem volor saped maio.',
    'Tečaj vodijo znani nogometni analitiki': 'Tečaj vodijo znani nogometni analitiki.',
    'SPOZNAJ EKIPO': 'MEET THE TEAM',
    'DOSEŽKI': 'DOSTIGNUĆA:',
    'NAGRADE': 'NAGRADE:',
    "Aktualne teme in novice, ki vam bodo v pomoč pri uspešnem bla bla": "Aktualne teme in novice, ki vam bodo v pomoč pri uspešnem bla bla.",
    'Hvala za poslano sporočilo': 'Thank you for your message.',
    'V najkrajšem času ti bomo odpisali intipodali želene informacije': 'We will respond as soon as possible and provide you with all the necessary information.',
    'Lep pozdrav,': 'Best regards,',
    'ekipa AFI.': 'the AFI team.',

    'ZAPRI': 'CLOSE',
    //Kontakt form /***********************/
    'Pozdravljen!': 'Hi!',
    'Zahvaljujemo se za izkazaninteres.': 'Thank you for your interest in the football analyst course.',
    'Na desni strani izpolni potrebneinformacije innam napiši sporočilo.': 'Please fill out the fields on the right with the required information and send us a message.',
    'KONTAKTNI OBRAZEC': 'CONTACT FORM',
    'Ime': 'First name:',
    'Priimek': 'Last name:',
    'E-naslov': 'E-mail:',
    'Telefonska št': 'Phone:',
    'Sporočilo': 'Message:',
    'POŠLJI': 'SEND',
    'cookie_text1': 'Da bi vam omogočili boljšo uporabniško izkušnjo, na naših spletnih straneh uporabljamo piškotke. Prosimo, izberite, katere piškotke želite uporabljati.',
    'Nujno potrebni piškotki': 'Nujno potrebni piškotki',
    'cookie_text2': 'Ti piškotki zagotavljajo delovanje naše spletne strani, zato je njihova uporaba nujna za brskanje po njej. Piškotki bodo samodejno izbrisani iz vašega računalnika po zaključku trenutne seje ali zaprtju brskalnika.',
    'Funkcijski piškotk': 'Funkcijski kolačići',
    'cookie_text3': 'Ti piškotki zbirajo statistične podatke o uporabi spletnega mesta, ne da bi osebno identificirali posamezne obiskovalce. Uporabljamo jih za prilagajanje naše spletne strani potrebam naših obiskovalcev.',
    'Marketinški in statistični piškotki': 'Marketinški in statistični piškotki',
    'cookie_text4': 'Ti piškotki nam omogočajo, da bolje razumemo vaše interese (in sicer z razumevanjem vaše dejavnosti brskanja na našem spletnem mestu) in prikazujemo vam prilagojene oglase, glede na področja, ki vas zanimajo.',
    'SHRANI': 'SAČUVAJ',
    'SPREJMI VSE': 'PRIHVATI SVE',
    'cookie_text5': 'To provide you with a better user experience, we use cookies on our websites. For more information, visit the Privacy Policy page.',
    'Uredi nastavitve': 'Podešavanja',
    'SPREJMI': 'ACCEPT',
    'Adria nogometni inštitut': 'Adria nogometni inštitut d.o.o.',

    'Vnesti morate vsa polja': 'You must enter all fields.',
    'Prišlo je do napake': 'An error has occurred.',
    'January':'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'May': 'May',
    'June':'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Program': 'Program',
    'Predavatelji': 'Presenters',
    'Blog': 'News',
    'O nas': 'About',
    'Tečaj zajema 6 modulov s področja nogometne analitike': 'The course covers six modules from the field of football analytics.',
    'Prvi napredni tečaj za nogometnega analitika na Balkanu. V 6 mesecih (6 modulov) pod budnim mentorstvom izkušenih trenerjev in analitikov boš pridobili naziv analitika ter spoznali vrhunske računalniške programe, ki jih uporabljajo izkušeni analitiki v najboljših evropskih klubih.': 'This is the first advanced football analyst course in the Balkans. Over the course of six months (six modules) you will acquire the title of football analyst and learn to use state of the art software used by experienced analysts working in the top European clubs, all under the close mentorship of experienced coaches and analysts. ',
    "Tečaj se bo izvajal v srbo-hrvaškem jeziku. Udeležencem tečaja bo na voljo slovensko govoreči mentor.": "The course content is delivered in Serbo-Croat. Course participants will have access to Slovene, Croat and Serb speaking mentors.",
    'Tečaj vodijo izkušeni nogometni analitiki': 'The course is delivered by expert football analysts.',
    'Aktualne teme in novice, ki vam bodo v pomoč pri uspešnem.': 'Current topics and news from the world of football.',
    'Privacy policy': 'Privacy Policy',
    'program': 'program',
    'blog': 'news',
    'vjesti': 'news',
    'novice': 'news',
    'vesti': 'news',
    'predavatelji': 'presenters',
    'predavači': 'presenters',
    'onas': 'about',
    'o-nas': 'about',
    'o-nama': 'about',
    'privacy': 'privacy',
       'Stran ni najdena': 'Stranica nije pronađena. Molimo vas idite na početnu stranicu.',
       'Give away text': 'V kolikor želite sodelovati v nagradni igri in osvojiti vstopnice tekmo svetovnega prvenstva v Katarju , prosimo vnesite vaš e-mail naslov. Nagrajenci bodo izžrebani in objavljeni po zaključenem glasovanju.',
       'Send': 'Send',
       'Please enter valid email': 'Please enter valid email.',
       'Successfully sent': 'Successfully sent'
}

export default sr
