import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import sl from './lng/sl'
import en from './lng/en'
//import i18next from "i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            sl: {
                translation: sl
            },
            en: {
                translation: en
            },
        },
        lng: "sl",
        supportedLngs: ['sl', 'en'],
        interpolation: {
            escapeValue: false
        }
    });


