import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const NavigationVote = (props) => {

    const { step, setStep, classes, setSelecting, top11 } = props
    const { t } = useTranslation();

    const checkPlayers = () => {
        if (top11?.G?.length === 0) return false
        else if (top11?.D?.length < 4) return false
        else if (top11?.M?.length < 3) return false
        else if (top11?.F?.length < 3) return false
        else return true
    }

    const checkCoach = () => {
        if (top11?.C?.length === 0) return false
        else return true
    }

    const checkPlayer = () => {
        if (top11?.T?.length === 0) return false
        else return true
    }

    return (
        <div className={classes.sxl_select_players_nav}>
            <div className={classes.sxl_main_logo}>
                <Link to="/">
                    <img src={require("../img/logo.png")} alt="SpinsXL logo" />
                </Link>
            </div>
            <div className={classes.sxl_steps_holder}>
                <div className={`${classes.sxl_step} ${classes.sxl_step_active}`}
                    style={{ color: 'white' }}
                    onClick={() => {
                        /*     if (step.position !== 1) {
                                 setSelecting((prevState) => ({
                                     bestPlayerSelecting: false,
                                     bestCoachSelecting: false,
                                     playerSelecting: true,
                                 }));
                                 setStep({ position: 1 })
                             } */
                    }}
                   /* style={{ cursor: step.position !== 1 ? 'pointer' : undefined }} */>
                    <img src={checkPlayers() ? require(`../img/checkmark${"-active"}.svg`) : require(`../img/checkmark.svg`)} alt="checkmark" /> {t('Select Players')}
                </div>

                <div className={`${classes.sxl_step} ${step.position === 1 ? '' : classes.sxl_step_active}`}
                    style={{ color: 'white' }}
                    onClick={() => {
                        /* if (step.position === 3) {
                             setSelecting((prevState) => ({
                                 bestCoachSelecting: true,
                                 playerSelecting: false,
                                 bestPlayerSelecting: false,
                             }));
                             setStep({ position: 2 })
                         } */
                    }}
                 /*   style={{ cursor: step.position === 3 ? 'pointer' : undefined }} */>
                    <img src={step.position === 1 ? require("../img/checkmark.svg") : checkCoach() ? require(`../img/checkmark${"-active"}.svg`) : require("../img/checkmark.svg")} alt="checkmark" />{t('Select Coach')}</div>

                <div className={`${classes.sxl_step} ${step.position === 3 ? classes.sxl_step_active : ''}`}
                    style={{ color: 'white' }}>
                    <img src={step.position === 3 ? checkPlayer() ? require(`../img/checkmark${"-active"}.svg`) : require("../img/checkmark.svg") : require("../img/checkmark.svg")} alt="checkmark" /> {t('Best player')}
                </div>
            </div>
        </div >
    )
}

export default NavigationVote;