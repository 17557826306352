import axios from 'axios';

const getBest11Indices = (nomination) => {
    const best11 = [];
    for (let position in nomination) {
        if (position !== 'C' && position !== 'T') {
            let pos = position
            nomination[pos].forEach((player) =>
                best11.push(player.id),
            );
        }
    }
    return best11;
};

export const getAppStatus = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/app`, {});
        return res.data.status;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (hash) => {
    // Get a token from api server using the fetch api
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/me`,
            {
                method: 'GET',
                params: { voting_hash: hash },
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getClubs = async (hash) => {
    // Get a token from api server using the fetch api
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/clubs`, {
            method: 'GET',
            params: hash !== '' ? { voting_hash: hash } : '',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const canVote = async (hash) => {
    // Get a token from api server using the fetch api
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/votes/canvote`,
            {
                method: 'GET',
                params: hash !== '' ? { voting_hash: hash } : '',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getPlayers = async (searchPlayer, selectedClub, selectedPosition, sort, hash, orderby) => {

    let paramsData = {}
    if (searchPlayer) paramsData['lastname_contains'] = searchPlayer
    if (selectedPosition !== 'all') paramsData['position'] = selectedPosition
    if (selectedClub !== 'all') paramsData['club'] = selectedClub
    if (hash) paramsData['voting_hash'] = hash
    if (sort) paramsData['_sort'] = sort === 'stat.spins_index' ? 'stat.instat_index' + ':' + orderby : sort + ':' + orderby

    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/public`, {
            method: 'GET',
            params: paramsData,
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const sendVotes = async (top11, hash) => {
    const best11 = getBest11Indices(top11);

    const data = {
        top_11: best11,
        best_player: top11.T[0].id,
        best_coach: top11.C[0].id,
        voting_hash: hash,
    };
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/votes`, {
            method: 'POST',
            data: JSON.stringify(data),
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getResults = async (audience, period) => {

    let URL = `${process.env.REACT_APP_API}/results?audience=${audience}&period=${period}`
    try {
        const res = await sendRequest(
            URL,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getCarousel = async () => {

    let URL = `${process.env.REACT_APP_API}/carousels?_sort=OrderIndex:ASC`
    try {
        const res = await sendRequest(
            URL,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getParams = async () => {
    try {
        const res = await sendRequest(
            `${process.env.REACT_APP_API}/parameters?_sort=order_index:ASC`,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getActivePeriod = async () => {
    try {
        const res = await sendRequest(
            `${process.env.REACT_APP_API}/periods?&active=true`,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getPeriods = async () => {
    try {
        const res = await sendRequest(
            `${process.env.REACT_APP_API}/periods?_sort=id:DESC`,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getRulesText = async () => {

    let URL = `${process.env.REACT_APP_API}/rules`
    try {
        const res = await sendRequest(
            URL,
            {
                method: 'GET',
            },
        );
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const sendEmail = async (email, id) => {
    const data = {
        voter_email: email,
        vote: id
    };
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/giveaways`, {
            method: 'POST',
            data: JSON.stringify(data),
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const sendRequest = async (url, options) => {

    const headers = {
        'Content-Type': 'application/json',
    };

    try {
        return await axios(url, {
            headers,
            ...options
        });
    } catch (error) {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            throw new Error(error?.response?.data?.message);
        } else if (error?.response) {
            console.log(error);
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //422 handle to be done
            throw new Error(error?.response?.data?.message);
        } else if (error?.message) {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error.message);
        } else {
            throw new Error('Error');
        }
    }
};
