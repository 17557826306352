import React from 'react'

export function useWindowSize() {
    const [size, setSize] = React.useState([window.innerHeight, window.innerWidth]);
    React.useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        }
        window.addEventListener("resize", handleResize);
        // Clean up!
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}

