import React from 'react'
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

// styles
import classes from "./SelectBestPlayerField.module.css";

// utils
import { useWindowSize } from '../utils/windowSize'

const SelectCoachField = (props) => {

  const { onOpenRight, onPlayerClick, otherState, setSelecting, vote } = props;
  const { t } = useTranslation();
  const [height, width] = useWindowSize();

  const handleClickNext = () => {

    if (otherState?.top11?.T?.length === 0) toast.warning(t("Please select best player"))
    else {
      setSelecting(() => ({
        bestCoachSelecting: false,
        playerSelecting: false,
        bestPlayerSelecting: false,
      }));
      vote()
    }
  }

  const handleClick = (position, number) => {
    if (otherState?.top11[position][number]) {
      onPlayerClick(position, otherState.top11[position][number])
    } else {
      onPlayerClick(position)
      if (width < 767) onOpenRight()
    }
  }

  return (
    <div className={classes.sxl_select_coach_left}>
      <img
        src={require("../img/field_1.png")}
        alt="field bg"
        className={classes.sxl_field_bg}
      />
      <img
        src={require("../img/field_small.png")}
        alt="field bg"
        className={classes.sxl_field_bg_small}
      />

      <div className={classes.sxl_select_coach}>
        <div className={classes.sxl_coach_item}>
          <div className={classes.sxl_coach_img} onClick={() => handleClick('T', 0)}>
            <img src={otherState?.top11?.T[0]?.image ? `${process.env.REACT_APP_API}` + otherState?.top11?.T[0]?.image?.formats?.thumbnail?.url : require("../img/players/add.svg")} alt="add" />
          </div>
          {otherState?.top11?.T[0]?.lastname ?
            <div className={classes.sxl_coach_last_name}>
              {otherState?.top11?.T[0]?.lastname}
            </div> :
            <div className={classes.sxl_coach_position}>{t('Select best player')}</div>
          }
        </div>
      </div>
      <div className={classes.sxl_select_ctas}>
        <div
          className={classes.sxl_select_cta_white_small}
          onClick={onOpenRight}
        >
          {t('List of Coaches')}
        </div>
        {/* {<Link to="#">
          <div className={classes.sxl_select_cta_white}>
            Players of The Season
          </div>
        </Link>} */}
        <div className={classes.sxl_select_cta_black} onClick={() => handleClickNext()}>{t('Add vote')}</div>
      </div>
    </div>
  );
};

export default SelectCoachField;
