const slo = {
   'Part1': 'Oddaj svoj glas',
   'Part2': 'in izberi najboljše nogometaše in trenerja sezone 2023/2024',
   'Part3': 'v jesenskem delu',
   'Your vote you can submit until': 'Svoj glas lahko oddate še',
   '04. 04. 2021.': '04. 04. 2021.',
   'Vote Now': 'Glasujte zdaj',
   'Trenutno najboljših 11 v 1.SNL 2023/2024': 'Trenutno najboljših 11 v 1.SNL 2023/2024',
   'Pravila glasovanja': 'Pravila glasovanja',
   'Select Players': 'Naj 11',
   'Select Coach': 'Naj trener',
   'Best player': 'Naj igralec',
   'Fill in all the players': 'Izpolnite vse igralce.',
   'Goalkeepers': 'Vratarji',
   'Goalkeeper': 'Vratar',
   'Defenders': 'Branilci',
   'Defender': 'Branilec',
   'Midfielders': 'Vezisti',
   'Midfielder': 'Vezist',
   'Strikers': 'Napadalci',
   'Striker': 'Napadalec',
   'Coach': 'Trener',
   'Coaches': 'Trenerji',
   'Club': 'Klub',
   'List of Players': "Seznam igralcev",
   'List of Coaches': 'Seznam trenerjev',
   'Next': 'Naprej',
   "Please select coach": 'Izberite najboljšega trenerja',
   "Please select best player": "Izberite najboljšega igralca",
   "Select best player": "Izberite najboljšega igralca",
   'Choose Players': 'Izberite najboljših 11',
   'Choose Coach': 'Izberite najboljšega trenerja',
   'Choose best player': "Izberite najboljšega igralca",
   'Add vote': 'Oddaj glas',
   'Back': 'Nazaj',
   'All Clubs': 'Vsi klubi',
   'All Positions': 'Vsi položaji',
   'Search': 'Iskanje',
   'Players': 'Igralci',
   'Sort by': 'Razvrsti po',
   'Name': 'Ime',
   'Vote': 'Izberi',
   'noVoteMessage': 'V kolikor se nahajate na skupni WiFI točki, obstaja možnost, da je nekdo danes že oddal glas s tega IP naslova. Predlagamo, da preidete na mobilne podatke, osvežite stran in ponovno oddate glas.',
   'You Have Successfully Submitted': 'Uspešno ste oddali',
   'Your Vote': 'Vaš glas',
   'Share With Friends': 'Deli s prijatelji',
   'You vote as': 'Glasujete kot',
   'Check your voting ID': 'Preverite svoj glasovalni ID.',
   'You have already voted, if not, check your voting ID': 'Ste že glasovali, če ne, preverite svoj ID glasovanja.',
   'This position is already full': 'Igralna pozicija je že zapolnjena.',
   'User already voted today, please try again tomorrow': 'Uporabnik je danes že glasoval, poskusite znova jutri.',
   'Voting is currently open, you can submit your vote': 'Glasovanje je trenutno odprto, svoj glas lahko oddate',
   'Voting is not paused anymore, check out current results': 'Glasovanje ni več zaustavljeno, preverite trenutne rezultate',
   'only public votes': 'Samo glas javnosti',
   'Voting is currently paused': 'Glasovanje je trenutno zaustavljeno',
   'Try Again Later': 'Poskusite znova kasneje',
   'Voting is currently closed, final results will be published soon': 'Glasovanje je zaključeno, končni rezultati bodo objavljeni kmalu',
   'Go To Results': 'Pojdite na rezultate',
   'Page not found': 'Stran ni najdena',
   'Go To Home Page': 'Pojdite na domačo stran',
   'Best Coach': 'Najboljši trener',
   'Best 11': 'Najboljših 11',
   'Best Player': 'Najboljši igralec',
   'Best 11 Right Now': 'Najboljših 11 trenutno',
   'Player Information': 'Kartica igralca',
   'Total Index': 'Skupni indeks',
   'Remove': 'Odstrani',
   'Select': 'Izberite',
   'Player Statistics': 'Statistika igralcev',
   'Saves': 'Shrani',
   'Goals conceded': 'Prejeti goli',
   'Minutes played': 'Odigrane minute',
   'Goals': 'Gol',
   'Yellow cards': 'Rumeni kartoni',
   'Key passes': 'Ključni prehodi',
   'Passes into the penalty box': 'Podaja v kazenski prostor',
   'Dribbles': 'Dribling',
   'Dribbles successful': 'Driblanje uspešno',
   'Key passes accurate': 'Ključ gre natančno',
   '% of accurate_passes': '% točnih_pasov',
   'Air challenges, %': "Zračni izzivi, %",
   'Tackles won, %': 'Tackles zmagali, %',
   '% of challenges won': '% izzivov zmaga',
   'Ground challenges won, %': 'Temeljski izzivi zmagali, %',
   'Lost balls': 'Izgubljene žoge',
   'Matches played': 'Odigrane tekme',
   'Attacking passes accurate, %': 'Napadni podaji natančni, %',
   'Errors leading to goal': "Napake, ki vodijo do cilja",
   'Chances created': 'Priložnosti ustvarjene',
   'Chances % of conversion': 'Možnosti % konverzije',
   'Minutes played including short - data information': "Odigrane minute vključno s kratkimi podatki",
   '% of one touch passes accuracy': '% natančnosti z enim dotikom',
   'Defended shots': 'Defended shots',
   'Hits received': 'Hits received',
   'Untouched network': 'Untouched network',
   'Defended 11m': 'Defended 11m',
   'Duels won%': 'Duels won%',
   'Hits + assists': 'Hits + assists',
   'Successful passes%': 'Successful passes%',
   'Instat index': 'Instat index',
   'Intercepted passes': 'Intercepted passes',
   'Templates': 'Templates',
   'Air duels won%': 'Air duels won%',
   'No parameters': 'Brez parametrov',
   'Value': 'Vrednost',
   'closed': 'zaprto',
   'days': 'dni',
   'hours': 'h',
   'minutes': 'min',
   'seconds': 'sek',
   'Periods': 'Obdobje',
   'By Public': 'Izbor javnosti',
   'By Coach': 'Izbor trenerjev',
   'By Players': 'Izbor igralcev',
   'By Journalist': 'Izbor novinarjev',
   'Journalists': 'Novinarji',
   'spins_index_tooltip': 'Uvrstitev igralca glede na SPINS indeks. Višji kot je SPIN indeks, boljša je skupna zmogljivost in sposobnost igralca',
   'spins_index_tooltip1': 'Uvrstitev igralca glede na SPINS indeks. Višji kot je SPIN indeks, boljša je skupna zmogljivost in sposobnost igralca.',
   'Give away text': 'V kolikor želite sodelovati v nagradni igri in osvojiti vstopnici za finale nogometne Lige prvakov 2023/2024, prosimo vnesite vaš e-mail naslov. Nagrajenci bodo izžrebani in objavljeni po zaključenem glasovanju.',
   'Send': 'Pošlji',
   'Please enter valid email': 'Vnesite veljaven e-poštni naslov.',
   'Successfully sent': 'Hvala za vašo udeležbo v nagradni igri.'
}

export default slo
