import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom';

// pages
import Home from './pages/Home';
import SuccessfullySubmitted from './pages/SuccessfullySubmitted'
import ResultsPage from './pages/ResultsPage';
import Vote from './pages/Vote'
import NotFoundPage from './pages/NotFoundPage';

// utils
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './utils/ScrollToTop'

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop >
          <Switch>

            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/vote">
              <Vote />
            </Route>

            <Route exact path="/vote/:hash">
              <Vote />
            </Route>

            <Route path="/results">
              <ResultsPage />
            </Route>

            <Route exact path="/successfully-submitted">
              <SuccessfullySubmitted />
            </Route>

            <Route path="/paused">
              <NotFoundPage />
            </Route>

            <Route path="/closed" >
              <NotFoundPage />
            </Route>

            <Route path="*" >
              <NotFoundPage />
            </Route>

          </Switch>
        </ScrollToTop>
        <ToastContainer
          position="top-right"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
}

export default App;
